.App {
    text-align: center;
}

body {
    background-color: #282c34;
}

.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

@media only screen and (max-width: 600px) {
    .container {
        padding-top: 5vh;
        justify-content: normal;
    }
}

.form-control {
    font-size: 20px;
    border: none;
    background-color: #333842;
    color: white;
    width: 400px;
    max-width: 95vw;
    border-radius: .25rem;
}

.form-control:hover {
    background-color: #3e4450;
    color: white;
}

.form-control:focus {
    background-color: #49505e;
    color: white;
    box-shadow: 0 0 0 0.4rem rgba(0, 123, 255, .25);
}

.App-link {
    color: #61dafb;
}

.link-btn {
    font-size: 20px;
    border-radius: .25rem;
    color: white;
    background-color: #333842;
}

.link-btn a {
    color: white;
    text-decoration: none;
}

.link-btn button {
    border: none !important;
}

.link-btn:hover {
    color: white;
    background-color: #49505e;
    text-decoration: none;
}

.link-btn:focus {
    box-shadow: 0 0 0 0.4rem rgba(0, 123, 255, .25);
}

footer {
    position: absolute;
    bottom: 20px;
    width: 400px;
    max-width: 95vw;
}

.link-footer {
    color: #545c6c;
}

.link-footer:hover {
    color: #5f687a;
}

.dark-footer {
    color: #545c6c;
}
